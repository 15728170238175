/* #region page-container */

// all pages get this class
.common-page {
  font-family: $font-body;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  color: $color-dark;
  overflow-x: hidden;
  flex-grow: 1;
  height: 100vh !important;
  overflow-y: auto;
}

// all pages except frontpage get this class
// @todo: sobald der Multimandant steht, in basic-layout.scss background-image in :after schieben und mit Cottbus abgleichen: <FCP-1377>
.subpage {
  background-image: url("/assets/img/city_static.svg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position-y: 5vh;
  background-position-x: 40vw;
  background-size: 100% 140%;
}

.container-with-content-and-flyout {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow: hidden;
}

/* #endregion page-container */

/* #region page-header */
header {
  font-family: $font-heading;
  width: 100%;
}

header>.row {
  display: flex;
  padding: 1.875rem 0.938rem;
  align-items: center;
  margin: 0;

  @media screen and (min-width: $sm) {
    padding: 2rem 2.25rem 1rem;
  }

  .col {
    padding: 0;

    &:nth-child(1) {
      text-align: center;

      @media screen and (min-width: $lg) {
        text-align: left;
      }
    }

    &:nth-child(2) {
      text-align: center;
      justify-content: center;
      padding: 0 2rem;
      word-break: normal;
    }

    &:nth-child(3) {
      display: flex;
      justify-content: center;

      .icon-navigation {
        flex-wrap: nowrap;
      }

      @media screen and (min-width: $md) {
        justify-content: end;
        display: block;
      }
    }
  }

  .header-logo {

    // mobile logo;
    background-image: url($mobileLogoCodeOrUrl);
    width: 40px;
    height: 53px;
    background-size: 100%;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    align-items: initial;


    // only header-logos that are links, in marketplace header logo is not a link
    a.header-logo:hover {
      cursor: pointer;
    }

    // default logo
    @media screen and (min-width: $lg) {
      width: 140px;
      height: 54px;
      background-image: url($defaultLogo);
    }
  }

  /* 
  possibility to place a header-image in a third col between logo and nav, used only for custom client design, to be overwritten in custom-styles
  .custom-frontpage-header-div {
    background-image: url("/assets/img/...");
    min-height: 200px;
  }
  */
}

.page-title {
  font-weight: 700;
  font-size: 1rem;

  @media screen and (min-width: $md) {
    font-size: 1.25rem;
  }
}

/* #endregion page-header */

/* #region page-content */
.main-container {
  margin-bottom: 30px;
  padding: 1rem 1.25rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: $container-width;

  @media screen and (max-width: $sm) {
    padding: 0.625rem 1rem;
    margin: 0;
  }

  @media screen and (min-width: $sm) {
    padding: 1.25rem 1.25rem;
  }

  @media screen and (min-width: $md) {
    padding: 1.75rem 1.5rem;
  }

  @media screen and (min-width: $xl) {
    padding: 2.5rem 2.25rem;
  }

  &:has(.market-search) {
    max-width: unset;
  }
}

.row {
  margin: 0 0 1rem 0;
  grid-gap: 1rem;
}

.row-layout-with-equal-cols {
  flex: 1;
  text-align: left;

  @media screen and (min-width: $md) {
    text-align: center;
  }
}

.col {
  padding: 0;
}

// used for group of elements with gap, aligned left
.element-flex-layout {
  display: flex;
  gap: 0.2rem 1rem;
  flex-wrap: wrap;
}

/* #endregion page-content */

/* #region page-footer */
footer {
  font-family: $font-heading;
  width: 100%;
  // @todo Linda: delete if other solution for overlapping content is found, then set .frontpage::after back to height:100%
  padding-bottom: 70px;

  .footer-content {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding: 2rem 2.25rem 2.5rem;
    gap: 2rem;

    @media screen and (max-width: $sm) {
      padding: 1rem 1.25rem 1.5rem;
      flex-direction: column;
    }
  }

  .footer-links {
    text-align: center;
    flex-grow: 2;
    font-size: $font-size-sm;
    font-weight: 500;

    @media screen and (min-width: $md) {
      flex-grow: 3;
    }

    a {
      white-space: nowrap
    }
  }

  .footer__side-cols {
    flex-grow: unset;
  }

  .social-icons {
    transition: 0.2s;
    display: flex;
    gap: 2rem;
    justify-content: center;

    @media screen and (max-width: $sm) {
      margin: 1.5rem 0 0;
      text-align: center;
    }

    a {
      color: inherit;
      transition: all linear 0.125s;
      border: none;

      &:hover {
        opacity: 0.625;
      }
    }
  }
}

/* #endregion page-footer */