/* 
* constants, required for the import within constants.module.scss,
* to overwrite default constants for customization
*/

$color-fuchsia: #E06AFF;
$color-lachs: #EA8F7A;
$color-lemongelb: #FFFF86;

$color-primary: $color-lachs;
$color-primary-gradient: $color-fuchsia; // update/replace, if you want to have a gradiant in the color
$color-primary-light: #EA8F7A40;

$font-heading: "Onest", sans-serif, !default;
$font-body: "Poppins", sans-serif, !default;

// mobile-logo und favicon in der constants.module.scss gemacht werden
$mobileLogoCodeOrUrl: "/assets/img/sinn-sachsen/sinn-sachsen-logo-mobile.png";
$faviconUrl: "/assets/img/sinn-sachsen/favicon.ico";
$defaultLogo: "/assets/img/sinn-sachsen/logo-sinn-mit-projektfabrik.png";