/** 
  * If empty, still required for the import order in index.scss.
  * Overwrites mixins.scss for customer specific individualizations.
  * Copying the whole mixin with all attributes to overwrite only few is required.
**/
@mixin card--clickable {
  box-shadow: .25rem .25rem $color-dark;
  border: none;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    box-shadow: none;
  }
}