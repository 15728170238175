// custom logo + mobile logo
.header-logo {

  // mobile logo;
  width: 70px !important;
  height: 60px !important;

  // default logo
  @media screen and (min-width: $lg) {
    width: 145px !important;
    height: 52px !important;
  }
}

.frontpage {

  // background gradient as picture
  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    opacity: 1;
    background-image: url("/assets/img/sinn-sachsen/frontpage-bg.jpg");
    background-repeat: no-repeat;
    background-position-y: 0;
    background-position-x: 0;
    background-size: 100% 100%;
    min-height: 100vh;
  }

  .sponsor-logos {
    display: none !important;
  }
}

.sponsor-logos {
  display: flex;
  justify-content: center;
  gap: 2rem;
  align-items: center;
}

// card-style
.card {
  box-shadow: unset !important;
  border: 2px solid $color-dark;
}

// header
header>.row {
  padding: 0.5rem 1.5rem;

  &:first-child {
    border-bottom: 2px solid $color-gray-light;
    height: 70px;
  }

  // page-title
  &:nth-child(2) {
    margin-top: 1rem;

    .col {
      text-align: center;
    }
  }

  @media screen and (min-width: $sm) {
    padding: 0.5rem 1.5rem;
  }
}

// background-image city-wheel removed
.subpage {
  background-image: none;
}

/* #region dropdown */
.dropdown {
  .btn {
    box-shadow: none;
  }
}

.dropdown-menu {
  background-color: $color-white;
  background-image: none;
  box-shadow: none;
  border: 2px solid $color-dark;

  .dropdown-item {

    &:hover,
    &:active {
      background: #EA8F7A40;
    }

    &:focus-visible {
      background: #EA8F7A40;
    }
  }
}

.dropdown-menu::before {
  border: none;
}

/* #endregion dropdown */

/* #region fonts */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

label {
  font-family: $font-heading;
}

/* #endregion fonts */

/* #region buttons */

@mixin btn {
  border-radius: 0.625rem;
  border: solid 3px $color-dark;
  background-color: $color-white;
  box-shadow: .25rem .25rem $color-dark;

  &:hover {
    box-shadow: none;
    background-color: $color-white;
    border: solid 3px $color-dark;
  }
}

.btn-secondary {
  @include btn;

}

.primary-btn {
  @include btn;
  // height of primary-btn and secondary-btn is different
  height: unset;
  border: solid 2px $color-dark !important;
  background-image: none;
  background-color: $color-lemongelb !important;
}

/* #endregion buttons */

.actionicon {
  box-shadow: none;
}

.user-profile__picture__change-icon {
  border: none;
  background-color: transparent;
}
